<template>
  <form @submit.prevent="saveOrUpdateMarcoLegalGeneralVersion">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="mlg" class="form-label">Marco legal general:</label>
      <input
        :value="marcoLegalGeneral.name"
        class="form-control"
        id="mlg"
        disabled
      />
    </div>
    <div class="mb-3">
      <label for="mlgversion" class="form-label">Versión:</label>
      <input
        v-model="marcoLegalGeneralVersion.version"
        class="form-control"
        id="mlgversion"
        required
      />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import C422Errors from "@/components/C422Errors";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { find, createVersions } from "@/services/marcos_legales_generales";
import {
  find as findVersion,
  update,
} from "@/services/marcos_legales_generales_versions";
import { showNotification } from "@/services/notification";
export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      marcoLegalGeneralVersionId: this.$route.params.versionId,
      loading: false,
      errors: {},
      marcoLegalGeneralId: null,
      marcoLegalGeneral: { name: "" },
      marcoLegalGeneralVersion: { version: "" },
    };
  },
  mounted() {
    this.marcoLegalGeneralId = this.$route.params.id;
    this.getMarcoLegalGeneral();
    this.getMarcoLegalGeneralVersion();
  },
  methods: {
    getMarcoLegalGeneral() {
      find(this.marcoLegalGeneralId).then((response) => {
        this.marcoLegalGeneral = response.data;
      });
    },
    getMarcoLegalGeneralVersion() {
      if (!this.marcoLegalGeneralVersionId) {
        return;
      }
      findVersion(this.marcoLegalGeneralVersionId).then((response) => {
        this.marcoLegalGeneralVersion = response.data;
      });
    },
    saveOrUpdateMarcoLegalGeneralVersion() {
      this.loading = true;
      this.marcoLegalGeneralVersionId
        ? this.updateMarcoLegalGeneralVersion()
        : this.saveMarcoLegalGeneralVersion();
    },
    saveMarcoLegalGeneralVersion() {
      createVersions(this.marcoLegalGeneralId, this.marcoLegalGeneralVersion)
        .then(() => {
          this.loading = false;
          showNotification(201);
          this.$router.push(
            `/admin/marcos-legales-generales/${this.marcoLegalGeneralId}/versiones`
          );
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    updateMarcoLegalGeneralVersion() {
      update(
        this.marcoLegalGeneralVersionId,
        this.marcoLegalGeneralVersion
      ).then(() => {
        showNotification(201);
        this.$router.push(
          `/admin/marcos-legales-generales/${this.marcoLegalGeneralId}/versiones`
        );
      });
    },
  },
};
</script>
